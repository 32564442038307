/* eslint-disable prettier/prettier */
export enum Gender {
  male = "M",
  female = "F",
  nonbinary = "X"
}

export enum FieldType {
  text = "text",
  dropdown = "dropdown",
  multi_choice = "multi_choice",
  single_choice = "single_choice",
  phone = "phone",
  download = "file_download",
  /** Frontend Only */
  time = "time",
  undefined = ""
}

export type CustomQuestionValidationType =
  | "text"
  | "number"
  | "email"
  | "time"
  | "password";

export enum ApiErrorCodes {
  Success = 200,
  NoContent = 204,
  NotFound = 404,
  InternalServerError = 500
}

export enum QueryParams {
  language = "lng",
  currency = "cur"
}
